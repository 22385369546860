<template>
  <div id="Index">
    <div class="header">
      <div class="header_one">
        <div>
          <span>同学快起床</span>
        </div>
        <div></div>
      </div>
      <div class="hedaer_two">
        <div>
          <div>
            <p>欢迎关注,</p>
            <p>同学快起床,</p>
            <p>一款适合您的闹钟...</p>
            <img src="../../assets/title.webp" />
          </div>
        </div>
        <div>
          <div>
            <h2>同学快起床</h2>
            <p> 这是一款充满创意的、贴心的真人语音工具，专为睡个好觉、愉快起床、消除赖床而开发。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <div>
        <div>
          <h2>功能1:早上叫醒</h2>
        </div>
        <div>
          <div>
            <img src="../../assets/getup.jpg" alt="">
          </div>
          <div>
            <p>
              APP实现了对讲机功能。找几个朋友约定好，早上谁先醒来就叫醒大家。
              先醒来的朋友可以一键呼醒大家。
              此功能老少皆宜，从4岁的小朋友到100岁的老朋友都可以用。
              叫醒可以是免费的，也可以适当付费/收费，比某宝要便宜得多。
            </p>
          </div>
        </div>
      </div>
      <div>
        <div>
          <h2>功能2:闹钟叫醒</h2>
        </div>
        <div>
          <div>
            <img src="../../assets/clock.jpg" alt="" />
          </div>
          <div>
            <p>
              除了对讲机叫醒外，本App也提供了语音闹钟，并且录制闹铃非常方便，而且一个闹钟可以 设置多条闹铃循环播放。
              您可以把想对自己讲的话录成闹铃，自己叫醒自己。
              您还可以找熟悉的或陌生的朋友们来相互录制闹铃，相互加油打气。
            </p>
          </div>
        </div>
      </div>
      <div>
        <div>
          <h2>功能3:好友随时聊天互动</h2>
        </div>
        <div>
          <div>
            <img src="../../assets/chat.jpg" alt="" />
          </div>
          <div>
            <p>
              好友随时聊天互动
              本App特别适合好友之间的随时语音对讲。
              与微信语音留言不同，本App的对讲语音发出后，无需对方手动去点击收听，而是直接会在对方手机自动播放（前提是对方对您未设免打扰）。
            </p>
          </div>
        </div>
      </div>
      <div>
        <div>
          <h2>功能4:改善睡眠</h2>
        </div>
        <div>
          <div>
            <img src="../../assets/sleep.jpg" alt="" />
          </div>
          <div>
            <p>
              高质量的睡眠有助于愉快起床，所以本app集成了提升睡眠质量的音频。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="c_footer">
        <div>
          <h3>高质量的睡眠,全新的闹钟模式,独特的</h3>
          <h3>起床困难症治疗方案,让起床不再困难</h3>
        </div>
        <div>
           <div>
            <i class="el-icon-alarm-clock
"></i>
             <p>适合你的闹钟铃声</p>
           </div>
           <div>
              <i  class="el-icon-phone-outline
"></i>
              <p>全新的叫醒方式</p>
           </div>
           <div>
               <i class="el-icon-moon-night"></i>
               <p>专业的助眠音频</p>
           </div>
        </div>
    </div>
    <div style="display: block; width: 300px; height: 100px; margin:0 auto; font-size: 24px;">
      <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备18009598号-1</a>
    </div>
  </div>
</template>

<script>
import "./Index.css"
export default {

}
</script>
<style scoped>

</style>