<template>
  <div id="app">
    <IndexVue></IndexVue>
  </div>
</template>

<script>
import IndexVue from './components/Index/Index.vue';
export default {
  name: 'App',
  components:{
    IndexVue,
  }
  
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
html,body{
  width:100%;
  height:100%;
}
#app{
  width: 100%;
  overflow: hidden;
  background:rgb(231, 227, 227);
}
</style>
